import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import useStyles from './styles';
import { useHistory, useParams } from 'react-router-dom';
import {
  AvailableWarehouseProduct,
  Product,
  useGetAvailableWarehouseProductByIdQuery,
  useGetMyProductByIdQuery,
  useGetWarehouseProductLogByProductIdLazyQuery,
  useGetWarehousesListQuery,
  useRemoveProductMutation,
  Warehouse,
  WarehouseProductLog,
} from '../../../../generated/graphql';
import iconBarcode from '../../../../assets/img/icon_barcode_button.svg';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import { useSnackbar } from 'notistack';
import { BoxCentered } from '../../../../components/BoxCentered/BoxCentered';
import { ShowLoadingText } from '../../../../utils/helperComponents';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utils/constants';
import ModalBox from '../../../../components/ModalBox/ModalBox';
import { LIMIT_ITEM_PAGE, TRoutes } from '../../../../utils/helpers';
import MyProductForm from '../../../../components/MyProductForm/MyProductForm';
import HistoryOfMovementProductsTable from '../../../../components/HistoryOfMovementProductsTable/HistoryOfMovementProductsTable';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../../../utils/types';
import PhotoComponent from '../../../../components/UploadProductPhoto/PhotoComponent';

const MyProductDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMediaQueryXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [product, setProduct] = useState<Product | null>(null);
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);
  const [isOpenConfirmRemove, setOpenConfirmRemove] = useState(false);
  const [isOpenUnitForm, setIsOpenUnitForm] = useState(false);
  const [warehouseList, setWarehouseList] = useState<Warehouse[]>([]);
  const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
  const [availabilityProduct, setAvailabilityProduct] =
    useState<AvailableWarehouseProduct | null>(null);
  const [warehouseId, setWarehouseId] = useState(0);
  const [isShowStatistics, setIsShowStatistics] = useState(false);
  const [filter, setFilter] = useState<IFilters>({
    page: 1,
  });

  const { loading, error, data, refetch } = useGetMyProductByIdQuery({
    variables: { productId: Number(id) },
    fetchPolicy: 'network-only',
  });

  const { data: warehouses, error: warehousesError } =
    useGetWarehousesListQuery();

  const {
    loading: avaliableProductLoading,
    error: avaliableProductError,
    data: avaliableProductData,
    refetch: avaliableProductRefetch,
  } = useGetAvailableWarehouseProductByIdQuery({
    variables: { warehouseId: warehouseId?.toString(), productId: id },
  });

  const [
    warehouseProductLogByProductIdLazyQuery,
    {
      data: warehouseProductLogByProductIdData,
      loading: warehouseProductLogByProductIdLoading,
    },
  ] = useGetWarehouseProductLogByProductIdLazyQuery();

  const [removeProductMutation, { loading: removeProductLoading }] =
    useRemoveProductMutation();

  if (error) enqueueSnackbar(error.message, { variant: 'error' });
  avaliableProductError?.message &&
    enqueueSnackbar(avaliableProductError.message, { variant: 'error' });
  warehousesError?.message &&
    enqueueSnackbar(warehousesError.message, { variant: 'error' });

  useEffect(() => {
    if (warehouseId && id && isShowStatistics) {
      warehouseProductLogByProductIdLazyQuery({
        variables: {
          warehouseId: warehouseId.toString(),
          productId: id,
          offset: (filter.page! - 1) * LIMIT_ITEM_PAGE,
          limit: LIMIT_ITEM_PAGE,
        },
        onError: (error) => {
          if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, id, isShowStatistics, filter]);

  useEffect(() => {
    if (data?.getMyProductById) {
      setProduct(data?.getMyProductById as Product);
      setEditingProduct(data?.getMyProductById as Product);
    }
  }, [data]);

  useEffect(() => {
    if (!warehouses?.getWarehousesList?.rows) return;
    warehouses?.getWarehousesList?.rows &&
      setWarehouseList(warehouses?.getWarehousesList?.rows as Warehouse[]);
  }, [warehouses]);

  useEffect(() => {
    avaliableProductRefetch();
    setAvailabilityProduct(
      avaliableProductData?.getAvailableWarehouseProductById as AvailableWarehouseProduct,
    );
    // eslint-disable-next-line
  }, [warehouseId, avaliableProductData]);

  useEffect(() => {
    if (!warehouse) {
      setWarehouseId(0);
      return;
    }
    //@ts-ignore
    setWarehouseId(warehouse?.id);
    // eslint-disable-next-line
  }, [warehouse]);

  const handleRemove = () => {
    removeProductMutation({
      variables: {
        productId: Number(id),
      },
    })
      .then((value) => {
        if (value?.data?.removeProduct) {
          enqueueSnackbar(t('app.itemSuccessfullyDeleted'), {
            variant: 'success',
          });
        }
      })
      .catch((reason) => {
        enqueueSnackbar(reason.message, { variant: 'error' });
      })
      .finally(() => {
        history.push(TRoutes.SETTINGS_MY_PRODUCTS);
      });
  };

  return (
    <>
      {!loading ? (
        <>
          <Typography variant='h2' align='center' className={classes.mainTitle}>
            {t('app.viewProduct').toUpperCase()}
          </Typography>
          <List>
            <Grid
              container
              spacing={0}
              alignItems='stretch'
              style={{ marginBottom: '24px', marginTop: '24px' }}
            >
              <Grid item sm={6} xs={12}>
                <ListItem className={classes.itemInfo}>
                  ID:{' '}
                  {product?.barcodeId?.substring(
                    0,
                    product?.barcodeId?.length - 4,
                  )}
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  {t('app.created')}:{' '}
                  {moment(Number(product?.createdAt)).format(DATE_FORMAT)}
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  {t('app.name')}: {product?.name}
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  SKU: {product?.sku}
                </ListItem>
                {product?.fnsku && (
                  <ListItem className={classes.itemInfo}>
                    FNSKU: {product?.fnsku}
                  </ListItem>
                )}
                {product?.upc && (
                  <ListItem className={classes.itemInfo}>
                    UPC: {product?.upc}
                  </ListItem>
                )}
                <Divider />
                <ListItem className={classes.itemInfo}>
                  {t('app.warehouse')}
                </ListItem>
                <FormControl variant='standard'>
                  <Select
                    className={classes.warehouseSelect}
                    id='warehouse'
                    value={warehouse ? warehouse : 0}
                    onChange={(e) => {
                      setWarehouse(e.target.value as Warehouse);
                    }}
                  >
                    <MenuItem value={0}>{t('app.allWarehouses')}</MenuItem>
                    {warehouseList?.map((item: any) => (
                      <MenuItem key={item.code} value={item}>
                        {item.code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!avaliableProductLoading ? (
                  <>
                    <ListItem className={classes.itemInfo}>
                      {t('app.inTransit')}:{' '}
                      {availabilityProduct?.inTransitQuantity
                        ? availabilityProduct.inTransitQuantity
                        : 0}
                    </ListItem>
                    <ListItem className={classes.itemInfo}>
                      {t('app.available')}:{' '}
                      {availabilityProduct?.availableQuantity
                        ? availabilityProduct?.availableQuantity
                        : 0}
                    </ListItem>
                    <ListItem className={classes.itemInfo}>
                      {t('app.reserved')}:{' '}
                      {availabilityProduct?.reservedQuantity
                        ? availabilityProduct?.reservedQuantity
                        : 0}
                    </ListItem>
                  </>
                ) : (
                  <BoxCentered>
                    <ShowLoadingText name={t('app.product2')} />
                  </BoxCentered>
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                <ListItem className={classes.itemInfo}>
                  <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <PhotoComponent photoName={product?.photo} width='338px' />
                  </Box>
                </ListItem>
              </Grid>
            </Grid>

            <Divider />
            <ListItem className={classes.itemInfo}>
              {t('app.dimensionsAndWeightDeclared')}:{' '}
              {product?.declaredDimensions?.width}
              {' x '}
              {product?.declaredDimensions?.length}
              {' x '}
              {product?.declaredDimensions?.height} {t('app.cm')},{' '}
              {product?.declaredDimensions?.weight} {t('app.kg')}
            </ListItem>
            <ListItem className={classes.itemInfo}>
              {t('app.dimensionsAndWeightActual')}:{' '}
              {product?.actualDimensions?.width
                ? product?.actualDimensions?.width
                : 0}
              {' x '}
              {product?.actualDimensions?.length
                ? product?.actualDimensions?.length
                : 0}
              {' x '}
              {product?.actualDimensions?.height
                ? product?.actualDimensions?.height
                : 0}{' '}
              {t('app.cm')},{' '}
              {product?.actualDimensions?.weight
                ? product?.actualDimensions?.weight
                : 0}{' '}
              {t('app.kg')}
            </ListItem>
          </List>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>{t('app.description')}</TableCell>
                  <TableCell align='center'>{t('app.weightKg')}</TableCell>
                  <TableCell align='center'>{t('app.price')}</TableCell>
                  <TableCell align='center'>
                    {t('app.countryOfOrigin')}
                  </TableCell>
                  <TableCell align='center'>{t('app.brand')}</TableCell>
                  <TableCell align='center'>{t('app.hsCode2')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align='center'>
                    {product?.detailsDescriptionRU} /
                    {product?.detailsDescriptionEN}
                  </TableCell>
                  <TableCell align='center'>
                    {product?.actualDimensions?.weight ||
                      product?.declaredDimensions?.weight}
                  </TableCell>
                  <TableCell align='center'>
                    {`${product?.declaredValue} ${product?.currency}`}
                  </TableCell>
                  <TableCell align='center'>
                    {product?.country?.nameEng}
                  </TableCell>
                  <TableCell align='center'>{product?.trademark}</TableCell>

                  <TableCell align='center'>
                    {product?.code && (
                      <Tooltip title={t('app.opensInNewWindow')}>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={`https://tnved.info/TnvedTree?Code=${product?.code}`}
                        >
                          {product?.code}
                        </a>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.wrapButtons}>
            <Button
              fullWidth={isMediaQueryXs}
              variant='contained'
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${product?.barcodeId}`,
                );
              }}
            >
              <img
                src={iconBarcode}
                alt='barcode'
                className={`${classes.actionIcon}`}
                style={{ width: 20, height: 20 }}
              />
              {t('app.barcode')}
            </Button>
            <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
            <Button
              fullWidth={isMediaQueryXs}
              variant='contained'
              onClick={() => {
                setIsShowStatistics((prev) => !prev);
              }}
              startIcon={<InsertChartIcon />}
            >
              {t('app.movementHistory')}
            </Button>
            <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
            <Button
              variant='contained'
              onClick={() => {
                setIsOpenUnitForm(true);
                setEditingProduct(product);
              }}
              startIcon={<EditIcon />}
            >
              {t('app.edit')}
            </Button>
            <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
            <Button
              variant='contained'
              onClick={() => {
                setOpenConfirmRemove(true);
              }}
              startIcon={<CancelIcon />}
              disabled={
                !!product?.productAvailabilityQuantity?.inTransitQuantity ||
                !!product?.productAvailabilityQuantity?.availableQuantity ||
                !!product?.productAvailabilityQuantity?.reservedQuantity
              }
            >
              {t('app.delete')}
            </Button>
          </div>
          {isShowStatistics && (
            <>
              <Typography variant='h6' className={classes.tableTitle}>
                {t('app.operationsHistory')}
              </Typography>
              {!!warehouseId ? (
                <HistoryOfMovementProductsTable
                  history={
                    warehouseProductLogByProductIdData
                      ?.getWarehouseProductLogByProductId
                      ?.rows as WarehouseProductLog[]
                  }
                  count={
                    warehouseProductLogByProductIdData
                      ?.getWarehouseProductLogByProductId?.count as number
                  }
                  page={filter.page!}
                  setFilter={setFilter}
                  loading={warehouseProductLogByProductIdLoading}
                />
              ) : (
                <BoxCentered>
                  <Typography>{t('app.chooseWarehouse')}</Typography>
                </BoxCentered>
              )}
            </>
          )}
        </>
      ) : (
        <BoxCentered>
          <ShowLoadingText name={t('app.product2')} />
        </BoxCentered>
      )}
      <ModalBox isOpen={isOpenUnitForm} setOpen={setIsOpenUnitForm}>
        <MyProductForm
          refetch={refetch}
          editingProduct={editingProduct}
          setIsOpenUnitForm={setIsOpenUnitForm}
          setEditingProduct={setEditingProduct}
          isProduct
        />
      </ModalBox>
      <ModalBox isOpen={isOpenConfirmRemove} setOpen={setOpenConfirmRemove}>
        <Typography variant='h6'>{t('app.confirmItemDeletion')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => {
              setOpenConfirmRemove(false);
            }}
            color='secondary'
          >
            {t('app.close')}
          </Button>

          <Button
            variant='contained'
            onClick={() => {
              handleRemove();
              setOpenConfirmRemove(false);
            }}
            disabled={removeProductLoading}
          >
            {t('app.delete')}
          </Button>
        </div>
      </ModalBox>
    </>
  );
};

export default MyProductDetails;
